import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <h1 className="title">Privacy Policy</h1>
    <hr />
    <div className="content">
      <p>
        Grafig Ltd ("Grafig") operates the Karimmoulai.com website, which provides the Service (i.e. information about
        Karim Moulai's activities).
      </p>
      <p>
        This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of
        Personal Information if anyone decided to use our Service, the Karimmoulai.com website.
      </p>
      <p>
        If you choose to use our Service, then you agree to the collection and use of information in relation with this
        policy. The Personal Information that we collect are used for providing and improving the Service. We will not
        use or share your information with anyone except as described in this Privacy Policy.
      </p>
      <p>
        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are
        accessible at Karimmoulai.com, unless otherwise defined in this Privacy Policy.
      </p>
      <h2 className="subtitle">Information Collection and Use</h2>
      <p>
        For a better experience while using our Service, we may require you to provide us with certain personally
        identifiable information, including but not limited to your name, phone number, email, and postal address. The
        information that we collect will be used to contact or identify you.
      </p>
      <h2 className="subtitle">Log Data</h2>
      <p>
        We want to inform you that whenever you visit our Service, we collect information that your browser sends to us
        that is called Log Data. This Log Data may include information such as your computer's Internet Protocol ("IP")
        address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent
        on those pages, and other statistics. The Log Data can also include form data that you have inputted or
        submitted.
      </p>
      <h2 className="subtitle">Cookies</h2>
      <p>
        Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent
        to your browser from the website that you visit and are stored on your computer's storage.
      </p>
      <p>
        Our website uses these "cookies" to collection information and to improve our Service. You have the option to
        either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to
        refuse our cookies, you may not use our Service.
      </p>
      <h2 className="subtitle">Service Providers</h2>
      <p>We may employ third-party companies and individuals due to the following reasons:</p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p>
        We want to inform our Service users that these third parties have access to your Personal Information. The
        reason is to perform the tasks assigned to them on our behalf.
      </p>
      <h2 className="subtitle">Security</h2>
      <p>
        We value your trust in providing us your Personal Information, thus we are striving to use commercially
        acceptable means of protecting it. However, no method of transmission over the internet, or method of electronic
        storage is 100% secure and reliable, and we cannot guarantee its absolute security.
      </p>
      <h2 className="subtitle">Links to Other Sites</h2>
      <p>
        Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that
        site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the
        Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy
        policies, or practices of any third-party sites or services.
      </p>
      <p>Children's Privacy</p>
      <p>
        Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable
        information from children under 13. In the case we discover that a child under 13 has provided us with personal
        information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that
        your child has provided us with personal information, please contact us so that we will be able to take
        necessary actions.
      </p>
      <h2 className="subtitle">Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any
        changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are
        effective immediately, after they are posted on this page.
      </p>
      <h2 className="subtitle">General Data Protection Regulation (GDPR)</h2>
      <p>We are a Data Controller of your information.</p>
      <p>
        Grafig legal basis for collecting and using the personal information described in this Privacy Policy depends on
        the Personal Information we collect and the specific context in which we collect the information:
      </p>
      <ul>
        <li>Grafig needs to perform a contract with you;</li>
        <li>You have given Grafig permission to do so;</li>
        <li>Processing your personal information is in Grafig legitimate interests; or</li>
        <li>Grafig needs to comply with the law.</li>
      </ul>
      <p>
        Grafig will retain your personal information only for as long as is necessary for the purposes set out in this
        Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal
        obligations, resolve disputes, and enforce our policies.
      </p>
      <p>
        If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish
        to be informed what Personal Information we hold about you and if you want it to be removed from our systems,
        please contact us.
      </p>
      <p>In certain circumstances, you have the following data protection rights:</p>
      <ul>
        <li>The right to access, update or to delete the information we have on you;</li>
        <li>The right of rectification;</li>
        <li>The right to object;</li>
        <li>The right of restriction;</li>
        <li>The right to data portability; and</li>
        <li>The right to withdraw consent.</li>
      </ul>
      <h2 className="subtitle">Contact Us</h2>
      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
    </div>
  </Layout>
);

export default PrivacyPolicyPage;
